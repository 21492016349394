import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const ArticleBlog = () => {
  const { articleId } = useParams(); // Extract articleId from the URL
  const [article, setArticle] = useState(null);

  useEffect(() => {
    console.log("articleId", articleId);
    // Fetch the specific article by ID from the backend
    if (articleId) {
      axios
        .get(`http://localhost:8080/articles/${articleId}`)
        .then((response) => {
          console.log("Fetched article:", response.data);
          setArticle(response.data);
        })
        .catch((error) => {
          console.error("Error fetching the article:", error);
        });
    }
  }, [articleId]);

  if (!article) return <div>Loading...</div>;

  return (
    <>
      {/* Header Start */}
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h3 className="display-3 font-weight-bold text-white">Blog Detail</h3>
          <div className="d-inline-flex text-white">
            <p className="m-0">
              <a className="text-white" href="/">
                Home
              </a>
            </p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Blog Detail</p>
          </div>
        </div>
      </div>
      {/* Header End */}

      {/* Detail Start */}
      <div className="container py-5">
        <div className="row pt-5">
          <div className="col-lg-8">
            <div className="d-flex flex-column text-left mb-3">
              <p className="section-title pr-5">
                <span className="pr-2">Blog</span>
              </p>
              <h1 className="mb-3">{article.title}</h1>
              <h2 className="mb-3">{article.metaDescription}</h2>
              <div className="d-flex">
                <p className="mr-3">
                  <i className="fa fa-user text-primary"></i> Admin
                </p>
                <p className="mr-3">
                  <i className="fa fa-folder text-primary"></i> Education
                </p>
                <p className="mr-3">
                  <i className="fa fa-comments text-primary"></i> 15
                </p>
              </div>
            </div>

            {/* Render content as HTML */}
            <div
              className="mb-5 article"
              dangerouslySetInnerHTML={{ __html: article.content }}
            ></div>

            {/* Comment Form */}
            <div className="bg-light p-5">
              <h2 className="mb-4">Laisser un commentaire</h2>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Nom *</label>
                  <input type="text" className="form-control" id="name" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email *</label>
                  <input type="email" className="form-control" id="email" />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message *</label>
                  <textarea
                    id="message"
                    cols="30"
                    rows="5"
                    className="form-control"
                  ></textarea>
                </div>
                <div className="form-group mb-0">
                  <input
                    type="submit"
                    value="Envoyer"
                    className="btn btn-primary px-3"
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-4 mt-5 mt-lg-0">
            {/* Author Bio */}
            <div className="d-flex flex-column text-center bg-primary rounded mb-5 py-5 px-4">
              <h3 className="text-secondary mb-3">Firas</h3>
              <p className="text-white m-0">Enseignant D'Anglais.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End */}
    </>
  );
};

export default ArticleBlog;
