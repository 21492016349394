import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styling
import Sidebar from "../../components/Admin/Sidebar";
import axios from "axios";

// Add custom toolbar with image option
const modules = {
  toolbar: {
    container: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  },
};

const AddArticlePage = () => {
  const [articleData, setArticleData] = useState({
    title: "",
    metaDescription: "",
    content: "",
  });

  const [wordCount, setWordCount] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (value) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const images = doc.querySelectorAll("img");
  
    const promises = Array.from(images).map(async (img) => {
      if (img.src.startsWith("data:image")) {
        // Upload Base64 image to Cloudinary
        const formData = new FormData();
        formData.append("file", img.src);
        formData.append("upload_preset", "your_upload_preset"); // Replace with your Cloudinary upload preset
  
        try {
          const response = await axios.post(
            "https://api.cloudinary.com/v1_1/your_cloud_name/image/upload", // Replace with your Cloudinary API URL
            formData
          );
          img.src = response.data.secure_url; // Replace src with Cloudinary URL
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }
    });
  
    Promise.all(promises).then(() => {
      const serializedContent = doc.body.innerHTML;
      setArticleData((prev) => ({ ...prev, content: serializedContent }));
    });
  };
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     // Post articleData to your server
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_SERVER_URL}/articles`,
  //       articleData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  
  //     console.log("Article saved successfully:", response.data);
  //     alert("Article added successfully!");
  //   } catch (error) {
  //     console.error("Error saving article:", error);
  //     alert("Failed to add article. Please try again.");
  //   }
  // };

  
  // const handleContentChange = (value) => {
  //   // Convert images to Base64 if not already
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(value, "text/html");
  //   const images = doc.querySelectorAll("img");

  //   const promises = Array.from(images).map((img) => {
  //     if (img.src && !img.src.startsWith("data:")) {
  //       return fetch(img.src)
  //         .then((response) => response.blob())
  //         .then((blob) => {
  //           console.log("already base64");
  //           return new Promise((resolve) => {
  //             const reader = new FileReader();
  //             reader.onloadend = () => {
  //               img.src = reader.result; // Replace src with Base64 string
  //               resolve();
  //             };
  //             reader.readAsDataURL(blob);
  //           });
  //         });
  //     }
  //     console.log("already base64");
  //     return Promise.resolve(); // If already Base64 or missing src, skip it
  //   });

  //   Promise.all(promises).then(() => {
  //     // Serialize the modified content back to a string
  //     const serializedContent = doc.body.innerHTML;
  //     setArticleData((prev) => ({ ...prev, content: serializedContent }));
  //   });
  // };

  // Update word count whenever content changes
  useEffect(() => {
    const words = articleData.content.trim().split(/\s+/).filter(Boolean); // Split by whitespace and remove empty values
    setWordCount(words.length);
  }, [articleData.content]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Article Data:", articleData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/articles`,
        articleData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Article saved successfully:", response.data);
      // Redirect or show a success message
      alert("Article added successfully!");
    } catch (error) {
      console.error("Error saving article:", error);
      // Handle error, e.g., show an error notification
      alert("Failed to add article. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <Sidebar />
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        <h2 className="text-2xl mb-4 text-center font-bold">
          Edit Blog Article
        </h2>

        <form onSubmit={handleSubmit}>
          {/* Title Field */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="title"
            >
              Title:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="title"
              name="title"
              type="text"
              value={articleData.title}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Meta Description Field */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="metaDescription"
            >
              Meta Description:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="metaDescription"
              name="metaDescription"
              value={articleData.metaDescription}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Rich Text Editor */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Article Content:
            </label>
            <ReactQuill
              value={articleData.content}
              onChange={handleContentChange}
              theme="snow"
              placeholder="Write your article here..."
              className="h-64"
              modules={modules}
            />
          </div>

          {/* Content Size / Word Count */}
          <div className="text-right text-sm text-gray-600">
            Content Size: {wordCount} {wordCount === 1 ? "word" : "words"}
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Save Article
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddArticlePage;
