import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../../components/Admin/Sidebar";

const AdminDashboard = () => {
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch document counts from backend
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/documents/get/stats`)
      .then((response) => {
        // Transform the response data into the desired structure
        const transformedLevels = transformStats(response.data);
        setLevels(transformedLevels);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching document counts:", error);
        setLoading(false);
      });
  }, []);

  // Function to transform the stats from backend into the desired format
  const transformStats = (stats) => {
    // Predefined level names and the number of classes for each level
    const predefinedLevels = [
      { level: 1, name: "Primaire", numClasses: 6 },
      { level: 2, name: "De Base", numClasses: 4 },
      { level: 3, name: "Secondaire", numClasses: 3 },
    ];

    return predefinedLevels.map((levelData) => {
      const classes = [];
      for (let i = 1; i <= levelData.numClasses; i++) {
        const matchingStat = stats.find(
          (stat) =>
            stat._id.level === levelData.level && stat._id.docClass === i
        );
        classes.push({
          docClass: i,
          className: `${i === 1 ? "1ère" : `${i}ème`} année ${levelData.name}`,
          count: matchingStat ? matchingStat.count : 0,
        });
      }
      return { level: levelData.level, name: levelData.name, classes };
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  console.log(levels);
  return (
    <div className="min-h-screen flex items-center justify-center">
      <Sidebar />
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        {/* <h2 className="text-2xl mb-4 text-center">Primaire</h2> */}
        {levels.map((level) => (
          <div key={level.level} className="mb-8">
            <h3 className="text-2xl">{level.name}</h3>
            <div className="grid grid-cols-4 gap-4">
              {level.classes.map((cls) => (
                <div
                  key={cls.docClass}
                  className="bg-white shadow-md rounded-lg p-6 text-center border border-gray-200"
                >
                  <h4 className="font-semibold text-lg text-gray-700">
                    {cls.className}
                  </h4>
                  <p className="text-sm text-gray-500">
                <span className="text-green-500 text-[20px]">{cls.count}</span> documents added ✅
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
