import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="container-fluid bg-secondary text-white mt-5 py-5 px-sm-3 px-md-5">
      <div className="row pt-5">
        <div className="col-lg-3 col-md-6 mb-5">
          <a
            href="#"
            className="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0"
            style={{ fontSize: "40px", lineHeight: "40px" }}
          >
            <i className="flaticon-043-teddy-bear"></i>
            <span className="text-white">Digi-Class</span>
          </a>
          <p>votre première classe en ligne</p>
          <div className="d-flex justify-content-start mt-4">
            <a
              className="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center text-center mr-2 px-0"
              style={{ width: "38px", height: "38px" }}
              href="https://x.com/Digiclass_tn"
              //   target="_blank"
              // rel="noopener noreferrer"
            >
              
                <FaXTwitter />
            </a>
            <a
              className="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center text-center mr-2 px-0"
              style={{ width: "38px", height: "38px" }}
              href="https://www.facebook.com/profile.php?id=100091218900992"
              target="_blank"
              rel="noopener noreferrer"

            >
              <FaFacebookF />
            </a>
            <a
              className="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center text-center mr-2 px-0"
              style={{ width: "38px", height: "38px" }}
              href="https://www.linkedin.com/in/digiclass/"
              //   target="_blank"
              // rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              className="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center text-center mr-2 px-0"
              style={{ width: "38px", height: "38px" }}
              href="https://www.instagram.com/digiclasseducation/"
                target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-5">
          <h3 className="text-primary mb-4">Contactez-nous</h3>
          <div className="d-flex">
            <h4 className="fa fa-map-marker-alt text-primary"></h4>
            <div className="pl-3">
              <h5 className="text-white">Adresse</h5>
              <p>Tunisie</p>
            </div>
          </div>
          <div className="d-flex">
            <h4 className="fa fa-envelope text-primary"></h4>
            <div className="pl-3">
              <h5 className="text-white">E-mail</h5>
              <p>digiclasseducation@gmail.com</p>
            </div>
          </div>
          <div className="d-flex">
            <h4 className="fa fa-phone-alt text-primary"></h4>
            <div className="pl-3">
              <h5 className="text-white">Téléphone</h5>
              <p>+216 48 028 900</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-5">
          <h3 className="text-primary mb-4">Liens</h3>
          <div className="d-flex flex-column justify-content-start">
            <a className="text-white mb-2" href="#">
              <i className="fa fa-angle-right mr-2"></i>Accueil
            </a>
            <a className="text-white mb-2" href="#">
              <i className="fa fa-angle-right mr-2"></i>À propos de nous
            </a>
            <a className="text-white mb-2" href="#">
              <i className="fa fa-angle-right mr-2"></i>Nos classes
            </a>
            <a className="text-white mb-2" href="#">
              <i className="fa fa-angle-right mr-2"></i>Nos enseignants
            </a>
            <a className="text-white mb-2" href="#">
              <i className="fa fa-angle-right mr-2"></i>Notre blog
            </a>
            <a className="text-white" href="#">
              <i className="fa fa-angle-right mr-2"></i>Contactez-nous
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-5">
          <h3 className="text-primary mb-4">Bulletin d'information</h3>
          <form action="">
            <div className="form-group">
              <input
                type="email"
                className="form-control border-0 py-4"
                placeholder="Votre adresse e-mail"
                required="required"
              />
            </div>
            <div>
              <button
                className="btn btn-primary btn-block border-0 py-3"
                type="submit"
              >
                S'inscrire
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className="container-fluid pt-5"
        style={{ borderTop: "1px solid rgba(23, 162, 184, 0.2)" }}
      >
        <p className="m-0 text-center text-white">
          &copy;
          <a className="text-primary font-weight-bold" href="#">
            DIGI-CLASS
          </a>
          . Tous droits réservés
        </p>
      </div>
    </div>
  );
};

export default Footer;
