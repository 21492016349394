import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DocumentsDashboard from "./pages/DocumentsDash.js";
import EditDocumentPage from "./pages/Admin/EditDocument.js";
import HomePage from "./pages/Home.js";
import Header from "./pages/Header.js";
import Footer from "./pages/Footer.js";
import DocumentsByLevelAndClassPage from "./pages/DocumentsByLevelAndClassPage.js";
import DocumentDetail from "./pages/DocumentDetail";
import ContactPage from "./pages/ContactPage";
import LevelsPage from "./pages/LevelsPage";
import BlogPage from "./pages/BlogPage";
import ArticleBlog1 from "./pages/ArticleBlog1.js";
import AboutPage from "./pages/AboutPage";
import LevelPrimairePage from "./pages/LevelPrimairePage";
import LevelSecondairePage from "./pages/LevelSecondairePage";
import LevelBasePage from "./pages/LevelBasePage";
import LevelBacPage from "./pages/LevelBacPage";
import DocumentsTestPage from "./pages/DocumentsTestPage";
import ArticleBlog2 from "./pages/ArticleBlog2.js";
import ArticleBlog3 from "./pages/ArticleBlog3.js";
import LoginPage from "./pages/Admin/LoginPage.js";
import UnderDevelopmentPage from "./pages/LoginPage.js";
import DocumentForm from "./pages/Admin/DocumentForm.js";
import DocumentListWithSidebar from "./components/DocumentList.js";
import AdminLevelsPage from "./pages/Admin/AdminLevelsPage.js";
import AdminClassesPage from "./pages/Admin/AdminClassesPage.js";
import PrivateRoute from "./components/PrivateRoute";
import { Helmet } from "react-helmet";
import AdminBlogPage from "./pages/Admin/AdminBlogPage.js";
import AddArticlePage from "./pages/Admin/AddArticlePage.js";
import ArticleBlog from "./pages/ArticleBlog.js";
import AdminDashboard from "./pages/Admin/AdminDashboard.jsx";

function AdminLayout({ children }) {
  return (
    <div className="AdminLayout">
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7059589174629742"
          crossOrigin="anonymous"
        ></script>
        <meta name="google-adsense-account" content="ca-pub-7059589174629742" />
      </Helmet>
      {children}
    </div>
  );
}

function UserLayout({ children }) {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7059589174629742"
          crossOrigin="anonymous"
        ></script>
        <meta name="google-adsense-account" content="ca-pub-7059589174629742" />
      </Helmet>
      <Header />
      <div className="UserLayout">{children}</div>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Switch>
        {/* Admin Routes */}
        <Route path="/admin">
          <AdminLayout>
            <Switch>
              <Route path="/admin/login" component={LoginPage} />
              <PrivateRoute
                path="/admin/create-document"
                component={DocumentForm}
              />
              <PrivateRoute
                path="/admin/edit-document/:id"
                component={EditDocumentPage}
              />
              <PrivateRoute
                path="/admin/documents"
                component={DocumentListWithSidebar}
              />
              <PrivateRoute path="/admin/dashboard" 
              component={AdminDashboard}/>
              <PrivateRoute path="/admin/levels" component={AdminLevelsPage} />
              <PrivateRoute
                path="/admin/classes"
                component={AdminClassesPage}
              />
              <PrivateRoute path="/admin/blog" component={AdminBlogPage} />
              <PrivateRoute
                path="/admin/create-article"
                component={AddArticlePage}
              />
            </Switch>
          </AdminLayout>
        </Route>

        {/* User Routes */}
        <Route>
          <UserLayout>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route
                path="/documentsByLevelAndClasse/:level-:docClass"
                component={DocumentsByLevelAndClassPage}
              />
              <Route
                path="/documents/by-id/:documentId"
                component={DocumentDetail}
              />
              <Route path="/contact" component={ContactPage} />
              <Route path="/niveaux" component={LevelsPage} />
              <Route path="/primaire" component={LevelPrimairePage} />
              <Route path="/base" component={LevelBasePage} />
              <Route path="/secondaire" component={LevelSecondairePage} />
              <Route path="/bac" component={LevelBacPage} />
              <Route path="/testdocuments" component={DocumentsTestPage} />
              <Route path="/blog" component={BlogPage} />
              <Route path="/article1" component={ArticleBlog1} />
              <Route path="/article2" component={ArticleBlog2} />
              <Route path="/article3" component={ArticleBlog3} />
              <Route path="/article/:articleId" component={ArticleBlog} />
              <Route path="/apropos" component={AboutPage} />
              <Route path="/login" component={UnderDevelopmentPage} />
            </Switch>
          </UserLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
