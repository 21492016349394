import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Sidebar from "./Sidebar";

const EditDocumentForm = ({ documentData }) => {
  const [formData, setFormData] = useState({
    title: documentData.title,
    description: documentData.description,
    imagePath: documentData.imagePath,
    metaTitle: documentData.metaTitle,
    metaDescription: documentData.metaDescription,
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();

  // Track changes to form data fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the updated data, omitting unchanged fields
    const updatedData = {};

    if (formData.title !== documentData.title)
      updatedData.title = formData.title;
    if (formData.description !== documentData.description)
      updatedData.description = formData.description;
    if (formData.imagePath !== documentData.imagePath)
      updatedData.imagePath = formData.imagePath;
    if (formData.metaTitle !== documentData.metaTitle)
      updatedData.metaTitle = formData.metaTitle;
    if (formData.metaDescription !== documentData.metaDescription)
      updatedData.metaDescription = formData.metaDescription;

    // If no fields are updated, don't make the request
    if (Object.keys(updatedData).length === 0) {
      console.log("No changes detected");
      return;
    }

    try {
      // Send the PUT request with only the updated fields
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/documents/${documentData._id}`,
        updatedData
      );
      alert("Document updated successfully!🎉");

      setIsSuccess(true);
      history.push("/admin/documents");
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const convertToBase64 = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        imagePath: reader.result,
      }));
    };
    reader.onerror = (error) => {
      console.log("Error : ", error);
    };
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <Sidebar />
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        <h2 className="text-2xl mb-4 text-center">Edit Document</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title:
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              Description:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="5"
            ></textarea>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="imagePath"
            >
              Image Path:
            </label>
            {/* Show the current image path or preview */}
            {formData.imagePath && (
              <div className="mb-2 text-gray-600">
                {formData.imagePath.startsWith("data:image") ? (
                  <img
                    src={formData.imagePath}
                    alt="Image Preview"
                    className="w-20 h-20 object-cover"
                  />
                ) : (
                  <p>Current image: {formData.imagePath}</p>
                )}
              </div>
            )}
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="imagePath"
              name="imagePath"
              type="file"
              accept=".jpg, .png, .jpeg"
              onChange={convertToBase64}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="metaTitle"
              className="block text-sm font-medium text-gray-700"
            >
              Meta Title:
            </label>
            <input
              type="text"
              name="metaTitle"
              value={formData.metaTitle}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="metaDescription"
              className="block text-sm font-medium text-gray-700"
            >
              Meta Description:
            </label>
            <input
              type="text"
              name="metaDescription"
              value={formData.metaDescription}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          <button
            type="submit"
            className="bg-[#138496] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditDocumentForm;
