import React, { useEffect } from "react";

function AdSenseAd({
  client,
  slot,
  format = "auto",
  style = { display: "block" },
}) {
  useEffect(() => {
    try {
      // Only push the ad if it hasn't been pushed before
      if (window.adsbygoogle) {
        const ads = document.querySelectorAll(".adsbygoogle");
        ads.forEach((ad) => {
          if (!ad.hasAttribute("data-ad-client")) {
            ad.setAttribute("data-ad-client", client);
            ad.setAttribute("data-ad-slot", slot);
            ad.setAttribute("data-ad-format", format);
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        });
      }
    } catch (e) {
      console.error("AdSense error: ", e);
    }
  }, [client, slot, format]);

  return (
    <ins
      className="adsbygoogle"
      style={style}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
    ></ins>
  );
}

export default AdSenseAd;
