import React from "react";
import { Link, useHistory } from "react-router-dom";

const Sidebar = () => {
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    history.push("/");
  };

  return (
    <div className="w-64 bg-gray-800 text-white h-screen p-4">
      <h2 className="text-2xl font-semibold mb-6 text-white">Admin Panel</h2>
      <nav className="flex flex-col space-y-4">
        {/* <Link
          to="/admin/dashboard"
          className="hover:bg-gray-700 p-2  font-bold text-[18px] rounded"
        >
          Dashboard
        </Link> */}
        <Link
          to="/admin/documents"
          className="hover:bg-gray-700 p-2  font-bold text-[18px] rounded"
        >
          Documents
        </Link>
        <Link
          to="/admin/levels"
          className="hover:bg-gray-700 font-bold text-[18px] p-2 rounded"
        >
          Levels
        </Link>
        <Link
          to="/admin/classes"
          className="hover:bg-gray-700 font-bold text-[18px] p-2 rounded"
        >
          Classes
        </Link>
        <Link
          to="/admin/blog"
          className="hover:bg-gray-700 font-bold text-[18px] p-2 rounded"
        >
          Blog
        </Link>
        <button
          onClick={handleLogout}
          className="hover:bg-gray-700 p-2  font-bold text-[18px] rounded"
        >
          Logout
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
