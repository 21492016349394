import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const DocumentDetail = () => {
  const { documentId } = useParams();
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!documentId) {
        console.error("Invalid documentId");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}`
        );
        const data = response.data;

        if (data && data.filePath) {
          setDocument(data);
          setLoading(false);
        } else {
          console.error("Invalid data structure received from the server.");
        }
      } catch (error) {
        console.error("Error fetching document data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [documentId]);

  const getLevelName = (level) => {
    switch (level) {
      case 1:
        return "Primaire";
      case 2:
        return "Base";
      case 3:
        return "Secondaire";
      default:
        return "Niveau inconnu";
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  console.log("display file path");

  console.log(document);

  return (
    <>
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h3 className="display-3 font-weight-bold text-white">
            {document &&
              `${document.docClass}` &&
              `${getLevelName(document.level)}`}
          </h3>
          <div className="d-inline-flex text-white">
            <p className="m-0">
              <span className="text-white text-[20px]">
                {document && document.title}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="col-lg-9">
            {loading ? (
              <p className="pt-2 text-primary text-center font-bold text-xl">
                Please wait, we're getting things ready for you...
              </p>
            ) : (
              <div>
                <h5 className="pt-2 text-primary text-center">
                  Aperçu du document
                </h5>
                <p className="text-center">
                  {document.description || "No description available."}
                </p>
                <div
                  className="pdf-viewer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {document && document.filePath ? (
                    <Document
                      file={document?.filePath}
                      // file="http://localhost:3000/documents/document-1733837305700.pdf"
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={console.error}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={index}
                          pageNumber={index + 1}
                          width={800}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                        />
                      ))}
                    </Document>
                  ) : (
                    <p>PDF not available.</p>
                  )}
                </div>

                {/* <div className="mt-4 text-center">
                  <a
                    href={document?.filePath}
                    download
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary me-3"
                  >
                    Télécharger
                  </a>
                </div> */}
                <div className="mt-4 text-center">
                  <a
                    href={`${document.filePath}`}
                    download={document.filePath}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="btn btn-primary">Télécharger</button>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentDetail;
