import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../components/Admin/Sidebar";

const AdminBlogPage = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch articles from the backend
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/articles`
        );
        setArticles(response.data);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setError("Failed to load articles. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const handleEdit = (articleId) => {
    console.log(`Edit article with ID: ${articleId}`);
    // Implement your navigation or logic for editing
  };

  const handleDelete = async (articleId) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/articles/${articleId}`
        );
        setArticles(articles.filter((article) => article.id !== articleId));
        alert("Article deleted successfully!");
      } catch (error) {
        console.error("Error deleting article:", error);
        alert("Failed to delete article. Please try again later.");
      }
    }
  };

  return (
    <div className="flex mt-0">
      <Sidebar />
      <div className="flex-1 p-6">
        <div className="mb-4 flex justify-between items-center">
          <h2 className="text-2xl font-semibold">Blog Articles</h2>
          <Link to="/admin/create-article">
            <button className="bg-[#138496] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Add New Article
            </button>
          </Link>
        </div>

        <div className="overflow-x-auto w-full">
          {loading ? (
            <div className="text-black text-xl font-semibold text-center flex justify-center">
              Loading articles...
            </div>
          ) : error ? (
            <div className="text-red-500 text-xl font-semibold text-center flex justify-center">
              {error}
            </div>
          ) : articles.length === 0 ? (
            <div className="text-black text-xl font-semibold text-center flex justify-center">
              No articles found.
            </div>
          ) : (
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {articles.map((article) => (
                  <tr key={article.id}>
                    <td className="border-t px-6 py-4">{article.title}</td>
                    <td className="border-t px-6 py-4">
                      {article.metaDescription}
                    </td>
                    <td className="border-t px-6 py-4">
                      <Link to={`/article/${article._id}`}>
                        <button className="bg-[#2ba510] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">
                          Preview
                        </button>{" "}
                      </Link>
                      <button
                        className="bg-[#138496] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                        onClick={() => handleEdit(article.id)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => handleDelete(article.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminBlogPage;
