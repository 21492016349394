// import React, { useState, useEffect } from "react";
// import { Link, useParams } from "react-router-dom";
// import axios from "axios";
// import { useLocation } from "react-router-dom";

// const DocumentsByLevelAndClassPage = () => {
//   const location = useLocation();
//   const levelName = location.state?.levelName || "";
//   const className = location.state?.className || "";
//   const [documents, setDocuments] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const documentsPerPage = 8; // 4 per row, so 8 per page
//   const [loading, setLoading] = useState(true);
//   const { level, docClass } = useParams();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_SERVER_URL}/documents/by-level-and-class/${level}-${docClass}`
//         );
//         setDocuments(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [level, docClass]);

//   const indexOfLastDocument = Math.min(
//     currentPage * documentsPerPage,
//     documents.length
//   );
//   const indexOfFirstDocument = Math.max(
//     indexOfLastDocument - documentsPerPage,
//     0
//   );
//   const currentDocuments = documents.slice(
//     indexOfFirstDocument,
//     indexOfLastDocument
//   );

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <>
//       <div className="container-fluid bg-primary mb-5">
//         <div
//           className="d-flex flex-column align-items-center justify-content-center"
//           style={{ minHeight: "400px" }}
//         >
//           <h3 className="display-3 font-weight-bold text-white">
//             {levelName || "Les niveaux"}
//           </h3>
//           <div className="d-inline-flex text-white">
//             <p className="m-0">
//               <Link className="text-white" to="/">
//                 Accueil
//               </Link>
//             </p>
//             <p className="m-0 px-2">/</p>
//             <p className="m-0">{className || "Documents"}</p>
//           </div>
//         </div>
//       </div>
//       {loading ? (
//         <div className="pt-2 text-primary text-center font-bold text-xl">
//           Please wait, we're getting things ready for you...
//         </div>
//       ) : (
//         <div style={{ padding: "0 30px" }}>
//           <div className="row">
//             {currentDocuments.map((documentItem) => (
//               <div
//                 className="col-lg-3 col-md-4 col-sm-6 mb-4"
//                 key={documentItem._id}
//               >
//                 <div
//                   className="card border-0 bg-light shadow-sm d-flex flex-column pb-3"
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     height: "100%",
//                   }}
//                 >
//                   <div
//                     className="d-flex align-items-center"
//                     style={{
//                       flexDirection: "row",
//                       padding: "10px",
//                     }}
//                   >
//                     <div style={{ flex: "2", textAlign: "left" }}>
//                       <h5 className="card-title">{documentItem.title}</h5>
//                       <p className="card-text">
//                         {documentItem.description ||
//                           "Lorem Ipsum Description Just to test the document preview."}
//                       </p>
//                     </div>
//                     <div style={{ flex: "1", textAlign: "right" }}>
//                       <img
//                         src={documentItem.imagePath}
//                         alt={documentItem.title}
//                         style={{
//                           width: "160px",
//                           height: "190px",
//                           objectFit: "cover",
//                           borderRadius: "8px",
//                         }}
//                       />
//                     </div>
//                   </div>
//                   <div className="text-center">
//                     <a
//                       href={`/documents/by-id/${documentItem._id}`}
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <button className="btn btn-secondary">Aperçu</button>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <div className="d-flex justify-content-center">
//             {Array.from({
//               length: Math.ceil(documents.length / documentsPerPage),
//             }).map((_, index) => (
//               <button
//                 key={index}
//                 className={`btn ${
//                   currentPage === index + 1 ? "btn-primary" : "btn-secondary"
//                 } mx-1`}
//                 onClick={() => paginate(index + 1)}
//               >
//                 {index + 1}
//               </button>
//             ))}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default DocumentsByLevelAndClassPage;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";

const DocumentsByLevelAndClassPage = () => {
  const location = useLocation();
  const levelName = location.state?.levelName || "";
  const className = location.state?.className || "";
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const documentsPerPage = 8; // 4 per row, so 8 per page
  const [loading, setLoading] = useState(true);
  const { level, docClass } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/documents/by-level-and-class/${level}-${docClass}`
        );
        setDocuments(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [level, docClass]);

  // Pagination calculation
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = documents.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );

  const paginate = (pageNumber) => {
    if (
      pageNumber < 1 ||
      pageNumber > Math.ceil(documents.length / documentsPerPage)
    )
      return;
    setCurrentPage(pageNumber);
  };

  console.log(`we have documents : ${currentDocuments.length}`);
  return (
    <>
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h3 className="display-3 font-weight-bold text-white">
            {levelName || "Les niveaux"}
          </h3>
          <div className="d-inline-flex text-white">
            <p className="m-0">
              <Link className="text-white" to="/">
                Accueil
              </Link>
            </p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">{className || "Documents"}</p>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="pt-2 text-primary text-center font-bold text-xl">
          Please wait, we're getting things ready for you...
        </div>
      ) : (
        <div style={{ padding: "0 30px" }}>
          <div className="row">
            {currentDocuments.map((documentItem) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 mb-4"
                key={documentItem._id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="card border-0 bg-light shadow-sm pb-2"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  {/* Zoom functionality commented out for now */}
                  {/**
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: documentItem.title,
                        isFluidWidth: true,
                        src: documentItem.imagePath,
                        sizes: "(max-width: 768px) 100vw, 200px",
                      },
                      largeImage: {
                        src: documentItem.imagePath,
                        width: 1200,
                        height: 1800,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "200%",
                      },
                      enlargedImageContainerStyle: {
                        zIndex: 1000,
                      },
                    }}
                  />
                  */}
                  <img
                    src={documentItem.imagePath}
                    alt={documentItem.title}
                    style={{
                      width: "70%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "50px",
                      marginTop: "10px",
                      alignItems: "center",
                    }}
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">{documentItem.title}</h5>
                    {/* <p className="card-text">
                      {documentItem.description ||
                          "No description available."}
                      </p>                     */}

                    <div className="flex justify-center">
                      <a
                        href={`/documents/${documentItem.filePath}`}
                        download={documentItem.filePath}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* <button className="btn btn-primary">
                          Télécharger
                        </button> */}
                      </a>
                      <a
                        href={`/documents/by-id/${documentItem._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="btn btn-secondary ml-2">
                          Aperçu
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            {Array.from({
              length: Math.ceil(documents.length / documentsPerPage),
            }).map((_, index) => (
              <button
                key={index}
                className={`btn ${
                  currentPage === index + 1 ? "btn-primary" : "btn-secondary"
                } mx-1`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentsByLevelAndClassPage;
